import moment from "moment-timezone";

export function dateFormat(date, format) {
  return moment(date).format(format);
}

export function dateFormatWithoutTimezone(date, format) {
  return moment.utc(date).format(format);
}

export function currencyFormat(value, currency) {
  let temp = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(value);

  if(temp == "" || temp == null || temp == undefined) {
    temp = currency+" 0"
  }
  return temp
}

// Format a string as title case
export function titleCase(str) {
  return str.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());
}

// Truncate a string to a specified length
export function truncate(str, length) {
  if (str.length > length) {
    return str.substring(0, length) + "...";
  } else {
    return str;
  }
}

// Convert a string to a slug
export function slugify(str) {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)+/g, "");
}

// Check if a value is empty
export function isEmpty(value) {
  return value === undefined || value === null || value === "";
}

// Check if a value is a number
export function isNumber(value) {
  return typeof value === "number" && isFinite(value);
}

// provide balance status all, open, close
export function getBalanceStatus(status) {
  return [
    { value: "all", text: "All" },
    { value: "open", text: "Open" },
    { value: "close", text: "Close" },
  ];
}

// create format number with two zero 1000 => 1,000.00
export function formatNumber(value) {
  console.log("formatNumber value : ", value)
  if(value == 0) {
    return "IDR 0"
  }
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "IDR",
  }).format(value);
}

// check if array or single value
export function isArray(value) {
  return Array.isArray(value);
}

// return value YES or NO
export function yesOrNo(value) {
  return value ? "YES" : "NO";
}
// make unique array
export function uniqueArray(arr) {
  return [...new Set(arr)];
}
// file to base64
export function fileToBase64(file) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    return reader.result;
  };

  reader.onerror = (error) => {
    return error;
  };
}

// return value bool with text format
export function boolToText(value, textTrue, textFalse) {
  return value ? textTrue : textFalse;
}
